<template>
  <v-container id="news-list" class="ma-0 pa-0" style="max-width:100%;">
    <div class="sticky" data-aos="zoom-in">
      <span class="ma-4 mt-0 font-poetsen contents-title">News</span>
      <span class="font-Yusei orange--text text--darken-2 contents-sub-title">最新情報</span>
    </div>
    <v-timeline
      align-top
      dense
      data-aos="zoom-in"
    >
      <v-timeline-item
        v-for="(news, i) in newsList"
        :key="i"
        :icon="news.icon"
        color="teal lighten-3"
        fill-dot
        style="overflow: hidden;"
      >
        <v-row class="pt-1">
          <v-col cols="4" class="px-0">
            <strong>{{news.create_date}}</strong>
          </v-col>
          <v-col class="pl-0 mr-2">
            <strong>{{news.title}}</strong>
            <div>
              <v-btn v-if="news.detail" text color="blue" @click="showDialog(news)">{{news.text}}</v-btn>
              <div v-else class="text-caption">{{news.text}}</div>
            </div>
          </v-col>
        </v-row>
      </v-timeline-item>
    </v-timeline>
    <v-dialog
      v-model="dialog"
      width="90%"
    >
      <v-card>
        <v-card-title class="text-h5 teal white--text ">
          <!-- <v-icon dark large>{{dialogObj.icon}}</v-icon> -->
          {{dialogObj.title}}
        </v-card-title>
        <v-img
          v-if="dialogObj.detail_image"
          :src="'/img/' + dialogObj.detail_image"
          max-height="300"
          contain
        ></v-img>
        <v-card-text class="mt-4">
          {{dialogObj.detail}}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="teal"
            text
            @click="closeDialog"
          >
            閉じる
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  export default {
    name: 'NewsList',
    data: () => ({
      isActive: false,
      newsList: [
        {
          icon: "mdi-star",
          create_date: "2024-06-24",
          title: "スズキ愛車無料点検　実施中！！",
          text: "詳しくはクリック",
          detail: "９月末までスズキ愛車無料点検キャンペーンを実施しております。スズキのお車をお持ちの方はこの機会に愛車の点検を行ってみてはいかがでしょうか？",
          detail_image: "IMG_0802.PNG",
        },
        {
          icon: 'mdi-map-marker-alert',
          create_date: '2024-06-17',
          title: '工場移転しました',
          text: '詳しくはクリック',
          detail: '同じうるま市石川内に移転しております。ホームページ内に掲載されているGooglMapをご確認ください。',
          detail_image: 'new_location.jpg',
        },
        {
          icon: 'mdi-application-outline',
          create_date: '2024-06-17',
          title: 'ホームページが公開されました',
          text: '当社のホームページを公開しました',
        }
      ],
      dialog: false,
      dialogObj: {
        icon: '',
        title: '',
        detail: '',
        detail_image: ''
      },
    }),
    methods: {
      showDialog(content) {
        this.dialogObj.icon = content.icon;
        this.dialogObj.title = content.title;
        this.dialogObj.detail = content.detail;
        this.dialogObj.detail_image = content.detail_image;
        this.dialog = true;
      },
      closeDialog() {
        this.dialogObj.icon = '';
        this.dialogObj.title = '';
        this.dialogObj.detail = '';
        this.dialogObj.detail_image = '';
        this.dialog = false;
      },
    },
    // async mounted() {
    //   let result = await fetch('/getNews').then(async resp => {
    //     return await resp.json();
    //   });
    //   this.newsList = [...result];
    // }
  }
</script>
<style scoped>
</style>